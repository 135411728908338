import useInterval from "@use-it/interval";
import { find, first, noop } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Bag from "../../api-services/bag";
import Check from "./check";
import Dialog from "./dialog";
import PendingCheckDrawer from "./pending-check-drawer";

export default function PurchasePoller({
  T,
  user,
  pageContext,
  app,
  appStyles,
  paymentTypeDetails,
  on = true,
  pollingInterval = 10000,
  displayPending,
  loadPaymentMethods,
}) {
  const {
    branches,
    business: { currencySymbol },
  } = pageContext;

  const [pendingCheck, setPendingCheck] = useState();
  const [openCheckId, setOpenCheckId] = useQueryParam("checkid", StringParam);
  const [fetching, setFetching] = useState(false);

  const getPendingChecks = useCallback(() => {
    if (fetching || !on) {
      return;
    }
    console.log("[PurchasePoller]: polling purchase events");
    setFetching(true);
    // setOpenCheckId(3);
    const bag = new Bag(user);
    bag
      .getChecksForUser()
      .then((checks) => {
        setFetching(false);
        const check = first(checks);

        if (check) {
          setPendingCheck({
            ...check,
            branch: find(branches, { id: check.branchId }),
          });
        } else {
          if (!openCheckId) {
            setPendingCheck(null);
          }
        }
      })
      .catch((error) => {
        setFetching(false);
      });
  }, [user, openCheckId, fetching, on]);
  const handleClose = () => {
    setOpenCheckId(null);
    setPendingCheck(null);
    getPendingChecks();
  };
  const handleOpen = () => setOpenCheckId(pendingCheck.id);
  useEffect(getPendingChecks, []); //called for first render

  useInterval(getPendingChecks, !openCheckId && on ? pollingInterval : null);

  if (!pendingCheck && !displayPending && !openCheckId) {
    return null;
  }

  return (
    <>
      <Dialog open={openCheckId} onClose={handleClose} title="Your Order" T={T}>
        <Check
          context={{
            pageContext,
            appStyles,
            app,
            user,
            T,
            loadPaymentMethods,
            paymentTypeDetails,
          }}
          checkId={openCheckId}
          onClose={handleClose}
        />
      </Dialog>
      <PendingCheckDrawer
        open={!openCheckId}
        onClose={noop}
        pendingCheck={pendingCheck}
        onOpenCheckDetails={handleOpen}
        T={T}
      />
    </>
  );
}
